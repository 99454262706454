import { requestToken } from '@/utils/request.js'

// 获取角色列表
export const getRoleList = (data) => {
  return requestToken('/role/queryList', data)
}

// 获取角色详情传
export const getRoleDetail = (data) => {
  return requestToken('/role/querySingle', data)
}

// 添加/编辑角色 saveSingle 添加 updateSingle 编辑
export const createRole = (type,data) => {
  return requestToken(`/role/${type}`, data)
}

// 删除角色
export const delRole = (data) => {
  return requestToken('/role/deleteSingle', data)
}

// 获取权限列表
export const getMenuList = (data) => {
  return requestToken('/menu/queryList', data)
}

