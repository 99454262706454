<template>
    <div class="flex-content">
        <!-- 头部 -->
        <div class="section-header flex-alignc-justifyb pdl-0 pdb-0">
            <div>部门/成员</div>
            <a-button @click="handleCreate">添加新成员</a-button>
        </div>
        <div class="flex-main pdl-0 pdb-0">
            <div class="flex bgfff h100" style="padding-top: 0.25rem">
                <div class="flex-sub1 flex-shrink0 scroll-y tree-list" style="flex: 0.6">
                    <a-tree
                            v-if="treeData.length"
                            :tree-data="treeData"
                            :defaultExpandAll="defaultExpandAll"
                            :replaceFields="{ key: 'id' }"
                            @select="onSelect"
                    >
                        <template #title="{ id, merchantName, level }">
                            <a-dropdown :trigger="['contextmenu']">
                                <span>{{ merchantName }}</span>
                                <template #overlay>
                                    <a-menu
                                            @click="
                      ({ key: menuKey }) => onContextMenuClick(id, menuKey)
                    "
                                    >
                                        <a-menu-item key="1">添加下级部门</a-menu-item>
                                        <template v-if="level != 0">
                                            <!-- <a-menu-item key="2">添加平级部门</a-menu-item> -->
                                            <a-menu-item key="3">编辑本部门</a-menu-item>
                                            <a-menu-item key="4">删除本部门</a-menu-item>
                                        </template>
                                    </a-menu>
                                </template>
                            </a-dropdown>
                        </template>
                    </a-tree>
                </div>
                <div class="flex-sub2 flex-directionc" style="width: 0">
                    <div class="pd-20">安装运维商演示商家</div>
                    <div class="flex-sub1" >
                        <!-- :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" -->
                        <a-table
                                :columns="columns"
                                :dataSource="tableData"
                                rowKey="id"
                                :scroll="{ x: 1200, y: yHeight }"
                                :pagination="false"
                                size="middle"
                        >
                            <div
                                    slot="operationSlot"
                                    slot-scope="text, record"
                                    class="flex-alignc-justifya operation-box"
                            >
                                <a-icon
                                        class="pointer"
                                        title="重置密码"
                                        type="key"
                                        @click="handleOpenReset(record)"
                                />
                                <a-icon
                                        class="pointer"
                                        title="编辑"
                                        type="edit"
                                        @click="handleCreate(record)"
                                />
                                <a-icon
                                        class="pointer"
                                        title="删除"
                                        type="delete"
                                        @click="handleDel(record)"
                                />
                            </div>
                        </a-table>
                    </div>
                    <div class="flex-alignc-justifyb pd-20">
                        <Pagination
                                :page.sync="pagination.page"
                                :pageSize.sync="pagination.limit"
                                :total.sync="total"
                                @paginationChange="getTableList"
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- 添加新成员弹窗 -->
        <a-drawer
                :bodyStyle="{
        padding: '0.3rem 0 0 0',
      }"
                :closable="false"
                :destroyOnClose="true"
                :headerStyle="{
        background: '#f0f1f5',
        padding: '0 0.2rem 0 0.3rem',
      }"
                :visible="visible"
                @close="closeTopDrawer"
                height="100%"
                placement="top"
                wrapClassName="create-power"
        >
            <template #title>
                <div class="head-box">
                    <span>添加成员</span>
                    <div class="head-btn">
                        <a-button @click="closeTopDrawer">取消</a-button>
                        <a-button class="save-btn" type="primary" @click="handleSubmit"
                        >保存
                        </a-button
                        >
                    </div>
                </div>
            </template>
            <div class="content-box">
                <div class="flex h100" style="width: 80%; margin: auto">
                    <a-form-model
                            ref="registerForm"
                            :model="registerForm"
                            :rules="registerRules"
                    >
                        <a-form-model-item label="成员姓名" prop="name">
                            <a-input
                                    v-model="registerForm.name"
                                    placeholder="成员姓名"
                            ></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="邮箱" prop="email">
                            <a-input
                                    v-model="registerForm.email"
                                    placeholder="邮箱"
                            ></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="登录手机号" prop="telPhone">
                            <a-input v-model="registerForm.telPhone"></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="性别" prop="sex">
                            <a-select v-model="registerForm.sex">
                                <a-select-option value="男">男</a-select-option>
                                <a-select-option value="女">女</a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item label="所在部门" prop="merchantId">
                            <a-tree-select
                                    v-model="registerForm.merchantId"
                                    style="width: 100%"
                                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                    :tree-data="treeData"
                                    :replaceFields="{ title: 'merchantName', value: 'id' }"
                                    placeholder="所在部门"
                                    tree-default-expand-all
                            >
                            </a-tree-select>
                        </a-form-model-item>
                        <a-form-model-item label="角色" prop="roleId">
                            <a-select v-model="registerForm.roleId">
                                <a-select-option
                                        v-for="(item, index) in roleList"
                                        :key="index"
                                        :value="item.id"
                                >{{ item.roleName }}
                                </a-select-option
                                >
                            </a-select>
                        </a-form-model-item>
                    </a-form-model>
                </div>
            </div>
        </a-drawer>

        <!-- 重置密码弹窗 -->
        <a-modal
                :visible="resetShow"
                @cancel="handleCancel('resetForm')"
                @ok="handleSubmitReset"
                title="重置密码"
        >
            <div>
                重置密码后，系统不会发短信或邮件通知成员。请将密码告知成员，确保其正常使用。
            </div>
            <a-form-model ref="resetForm" :model="resetForm" :rules="resetRules">
                <a-form-model-item label="密码" prop="password">
                    <a-input v-model="resetForm.password" placeholder="密码"></a-input>
                </a-form-model-item>
            </a-form-model>
        </a-modal>

        <!-- 添加/编辑部门弹窗 -->
        <a-modal
                :visible="departmentShow"
                @cancel="handleCancel('departmentForm')"
                @ok="handleSubmitDepartment"
                title="添加下级部门"
        >
            <a-form-model
                    ref="departmentForm"
                    :model="departmentForm"
                    :rules="departmentRules"
            >
                <a-form-model-item label="部门名" prop="merchantName">
                    <a-input
                            v-model="departmentForm.merchantName"
                            placeholder="可以是部门名称也可以是组织名称"
                    ></a-input>
                </a-form-model-item>
                <a-form-model-item label="所在上级部门" prop="parentId">
                    <a-tree-select
                            v-model="departmentForm.parentId"
                            style="width: 100%"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="treeData"
                            :replaceFields="{ title: 'merchantName', value: 'id' }"
                            placeholder="所属上级部门"
                            tree-default-expand-all
                    >
                    </a-tree-select>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
    import {
        getMerchantList,
        getMerchantDetail,
        createMerchant,
        delMerchant,
        getDepartmentMembers,
        getDepartmentMembersDetail,
        createDepartmentMembers,
        delDepartmentMembers,
        resetDepartmentMembersPassword,
    } from "@/api/user";
    import {getRoleList} from "@/api/role";
    import {debound} from "@/utils/index.js";

    export default {
        data() {
            return {
                defaultExpandAll: true,
                treeData: [], // 部门数据
                pagination: {
                    page: 1,
                    limit: 50,
                },
                total: 0,
                columns: [
                    {
                        title: "序号",
                        dataIndex: "index",
                        width: 70,
                        customRender: (text, record, index) => {
                            return index + 1;
                        },
                    },
                    {
                        title: "成员姓名",
                        dataIndex: "name",
                        width: 120,
                    },
                    {
                        title: "角色",
                        dataIndex: "roleName",
                        width: 120,
                    },
                    {
                        title: "部门",
                        dataIndex: "merchantName",
                        width: 120,
                    },
                    {
                        title: "手机号",
                        dataIndex: "telPhone",
                        width: 120,
                    },
                    {
                        title: "邮箱",
                        dataIndex: "email",
                        width: 120,
                    },
                    {
                        title: "最后登录",
                        dataIndex: "lastLoginTime",
                        width: 120,
                    },
                    {
                        title: "操作",
                        width: 120,
                        fixed: "right",
                        scopedSlots: {customRender: "operationSlot"},
                    },
                ],
                tableData: [],
                yHeight:
                    document.documentElement.clientHeight -
                    document.documentElement.clientHeight / 5.4,
                selectedRowKeys: [], // 批量数据
                roleList: [], // 角色列表
                visible: false, // 新增成员弹窗
                registerForm: {
                    name: "", // 姓名
                    email: "", // 邮箱
                    telPhone: "", // 手机登录账号
                    sex: "", // 性别
                    merchantId: "", // 所属部门/商户
                    roleId: "", // 角色
                },
                registerRules: {
                    name: [{required: true, message: "姓名不能为空", trigger: "change"}],
                    email: [
                        {required: true, message: "邮箱不能为空", trigger: "change"},
                        {
                            pattern:
                                /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
                            message: "邮箱格式不正确",
                        },
                    ],
                    telPhone: [
                        {required: true, message: "手机号不能为空", trigger: "change"},
                        {
                            pattern:
                                /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
                            message: "请输入正确手机号",
                        },
                    ],
                    sex: [{required: true, message: "性别不能为空", trigger: "change"}],
                    merchantId: [
                        {required: true, message: "所属部门不能为空", trigger: "change"},
                    ],
                    roleId: [
                        {required: true, message: "角色不能为空", trigger: "change"},
                    ],
                },

                resetShow: false, // 重置密码弹窗
                resetForm: {
                    password: "",
                },
                resetRules: {
                    password: [
                        {required: true, message: "密码不能为空", trigger: "change"},
                    ],
                },

                departmentShow: false, // 添加部门弹窗
                departmentForm: {
                    merchantName: "",
                    parentId: "",
                },
                departmentRules: {
                    merchantName: [
                        {required: true, message: "部门名不能为空", trigger: "change"},
                    ],
                    parentId: [
                        {required: true, message: "请选择上级部门", trigger: "change"},
                    ],
                },
            };
        },
        created() {
            this.getTableList();
            this.getMerchantList();
            this.getRoleList();
            this.watchResize();
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.screenResize);
        },
        methods: {
            watchResize() {
                window.addEventListener("resize", this.screenResize);
            },
            screenResize: debound(function () {
                // document.documentElement.clientHeight;
                this.yHeight =
                    document.documentElement.clientHeight -
                    document.documentElement.clientHeight / 5.4;
            }, 200),
            // 获取角色列表
            getRoleList() {
                getRoleList({}).then((res) => {
                    this.roleList = res.data;
                });
            },
            onSearch() {
            },
            onSelect(selectedKeys, info) {
                console.log("selected", selectedKeys, info);
            },

            // 获取部门列表
            getMerchantList() {
                getMerchantList().then((res) => {
                    console.log(res)
                    this.treeData = this.transTree(res.data);
                    this.defaultExpandAll = true;
                });
            },
            transTree(souceData) {
                const targetData = [];
                // 以每一项的id作为key,自身作为value形成对象结构
                const map = {};
                souceData.forEach((item) => {
                    map[item.id] = item;
                    item.children = [];
                });
                // 遍历数组通过parentId去匹配id,匹配到就添加到chilren属性中,匹配不到代表自身就是最外层的父节点,添加到最终的数组中
                souceData.forEach((item) => {
                    if (map[item.parentId]) {
                        // 匹配上
                        map[item.parentId].children.push(item);
                    } else {
                        // 没有匹配上
                        targetData.push(item);
                    }
                });
                // 返回的是处理之后的数组
                return targetData;
            },
            // 操作部门
            onContextMenuClick(treeKey, menuKey) {
                if (menuKey == 4) {
                    this.handleDelDepartment(treeKey); // 删除部门
                } else if (menuKey == 3) {
                    getMerchantDetail({
                        id: treeKey,
                    }).then((res) => {
                        this.departmentForm = res.data;
                        this.handleOpenDepartment(); // 打开部门弹窗
                    });
                } else {
                    this.departmentForm.parentId = treeKey;
                    this.handleOpenDepartment(); // 打开部门弹窗
                }
                // console.log(`treeKey: ${treeKey}, menuKey: ${menuKey}`)
            },
            // 删除部门
            handleDelDepartment(id) {
                let that = this;
                this.$confirm({
                    title: "确认删除该部门吗?",
                    okText: "确定",
                    okType: "danger",
                    cancelText: "取消",
                    onOk() {
                        delMerchant({
                            id,
                        }).then((res) => {
                            that.getMerchantList();
                        });
                    },
                });
            },
            // 打开部门弹窗
            handleOpenDepartment() {
                this.departmentShow = true;
            },
            // 添加/编辑部门
            handleSubmitDepartment() {
                this.$refs.departmentForm.validate((valid) => {
                    if (valid) {
                        createMerchant(
                            this.departmentForm.id ? "updateSingle" : "saveSingle",
                            this.departmentForm
                        ).then((res) => {
                            this.getMerchantList();
                            this.handleCancel("departmentForm");
                        });
                    }
                });
            },

            // 获取成员列表
            getTableList(pageMsg) {
                if (pageMsg?.page) {
                    this.pagination.page = pageMsg.page;
                    this.pagination.limit = pageMsg.pageSize;
                } else {
                    this.pagination.page = 1;
                    this.pagination.limit = 50;
                }
                getDepartmentMembers(this.pagination).then((res) => {
                    this.tableData = res.data;
                    this.total = res.count;
                });
            },
            // 全选
            onSelectChange(selectedRowKeys) {
                console.log("selectedRowKeys changed: ", selectedRowKeys);
                this.selectedRowKeys = selectedRowKeys;
            },

            // 删除成员
            handleDel(item) {
                let that = this;
                this.$confirm({
                    title: "确认删除该成员吗?",
                    okText: "确定",
                    okType: "danger",
                    cancelText: "取消",
                    onOk() {
                        delDepartmentMembers({
                            id: item.id,
                        }).then((res) => {
                            that.getTableList();
                        });
                    },
                });
            },

            // 添加/编辑成员
            handleCreate(item) {
                if (item.id) {
                    this.getDepartmentMembersDetail(item.id);
                }
                this.visible = true;
            },
            // 抽屉关闭
            closeTopDrawer() {
                this.visible = false;
                this.registerForm = {
                    id: "",
                    name: "", // 姓名
                    email: "", // 邮箱
                    telPhone: "", // 手机登录账号
                    sex: "", // 性别
                    merchantId: "", // 所属部门/商户
                    roleId: "", // 角色
                };
            },
            // 获取角色详情
            getDepartmentMembersDetail(id) {
                getDepartmentMembersDetail({
                    id,
                }).then((res) => {
                    this.registerForm = res.data;
                });
            },
            handleSubmit() {
                this.$refs.registerForm.validate((valid) => {
                    if (valid) {
                        createDepartmentMembers(
                            this.registerForm.id ? "updateSingle" : "saveSingle",
                            this.registerForm
                        ).then((res) => {
                            this.getTableList();
                            this.closeTopDrawer();
                        });
                    }
                });
            },
            // 关闭弹窗
            handleCancel(formName) {
                this.$refs[formName].resetFields();
                this.resetShow = false;
                this.departmentShow = false;
                this.resetForm = {
                    id: "",
                    password: "",
                };
                this.departmentForm = {
                    id: "",
                    merchantName: "",
                    parentId: "",
                };
            },
            // 打开重置密码弹窗
            handleOpenReset(item) {
                this.resetShow = true;
                this.resetForm.id = item.id;
            },
            // 重置密码
            handleSubmitReset() {
                this.$refs.resetForm.validate((valid) => {
                    if (valid) {
                        resetDepartmentMembersPassword(this.resetForm).then((res) => {
                            this.getTableList();
                            this.handleCancel("resetForm");
                        });
                    }
                });
            },
        },
    };
</script>

<style lang="less" scoped>
    .tree-list {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    /deep/ .ant-table {
        .operation-box {
            font-size: 16px;
        }
    }
</style>
